import { ApiRequest } from 'GlobalFunctions';
import { useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash';

export default function AutocompleteCities(props: any) {

    const { state_id, city_id, setCityId, pc_city, pc_state } = props;
    const [cities, setCities] = useState([]);
    const [inputPosition, setInputPosition] = useState({ top: 0, left: 0, width: 0 });
    const [isVisible, setIsVisible] = useState(false);

    const getCities = async (keyword: any) => {
        const regionElement = document.querySelector(`[pc="${pc_state}"]`) as HTMLSelectElement;
        const region_id = regionElement.value;
        await ApiRequest({
            url: '/countries/getCities',
            method: 'GET',
            query: {
                region_id,
                keyword
            },
            setResponse: (response: any) => {
                setCities(response);
            }
        });
        
    }

    const debouncedGetCities = useCallback(debounce(getCities, 300), []);

    useEffect(() => {
        const element = document.querySelector(`[pc="${pc_city}"]`);
        if (element) {
            const updatePosition = () => {
                const rect = element.getBoundingClientRect();
                setInputPosition({ 
                    top: rect.bottom + window.scrollY, 
                    left: rect.left + window.scrollX, 
                    width: rect.width 
                });
            };

            element.addEventListener("input", (e: any) => {
                const value = e.target.value;
                console.log(value);
                debouncedGetCities(value);
            });

            element.addEventListener("focus", () => {
                updatePosition();
                setIsVisible(true);
            });

            element.addEventListener("blur", () => {
                setIsVisible(false);
            });
        }
    }, [debouncedGetCities, state_id]);

    return (
        isVisible ? (
            <div
                className='cities_list'
                style={{ 
                    position: 'absolute', 
                    top: `${inputPosition.top}px`, 
                    left: `${inputPosition.left}px`, 
                    width: `${inputPosition.width}px`, 
                    backgroundColor: 'white', 
                    border: '1px solid #ccc', 
                    zIndex: 1000 
                }}
            >
                {cities.map((city: any) => (
                    <div
                        key={city.city_id}
                        className='autocomplete_item'
                        data-city-id={city.city_id}
                        data-city-name={city.city_name}
                        onMouseDown={() => {
                            const city_id = city.city_id;
                            const city_name = city.city_name;
                            console.log("CITY: ", city_name);
                            const element = document.querySelector(`[pc="${pc_city}"]`) as HTMLInputElement;
                            element.value = city_name || '';
                            setCityId(city_id);
                            setIsVisible(false);
                        }}
                    >
                        {city.city_name}
                    </div>
                ))}
            </div>
        ) : null
    );
}