import { ApiRequest, ShowLoader } from "GlobalFunctions";
import * as _ from 'lodash';
import { useEffect, useState } from 'react'; // Import useState
import { getRegions } from './Utils';
import Utils from './Utils';
import getOrderDetails from '../../Orders/getOrderDetails';


export default function ClientForm(props: any) {

    const {
        isFormComplete = () => {
            return {
                allFieldsFilled: false,
                incompleteFields: [],
            }
        },
        orderDetails = {},
        city_id, // Accept city_id as a prop
        s_city_id // Accept s_city_id as a prop
    } = props;

    const [isFormInitialized, setIsFormInitialized] = useState(false); // Add state variable

    useEffect(() => {
        if(!(Object.keys(orderDetails).length === 0)){
            SetClientInfo();
        }
    }, [orderDetails]);

    useEffect(() => {
        if ((city_id !== null || s_city_id !== null) && isFormInitialized) {
            collectFormData();
        }
    }, [city_id, s_city_id, isFormInitialized]);

    /*=========================================================
    COLLECT FORM DATA
    =========================================================*/
    const collectFormData = async () => {
        const params: { [key: string]: any } = {};
        const elements = document.querySelectorAll('input[pc], select[pc]');
        elements.forEach((element) => {
            const dataPc = element.getAttribute('pc');
            if (dataPc && [
                'fname', 
                'lname', 
                'address', 
                'address2', 
                'postal', 
                'city',
                'country', 
                'state', 
                's-fname', 
                's-lname', 
                's-address', 
                's-address2', 
                's-postal', 
                's-city', 
                's-country', 
                's-state',
                'email', 
                'phone', 
            ].includes(dataPc)) {
                params[dataPc] = (element as HTMLInputElement).value;
            }
        });

        /* Include city_id and s_city_id from props if they are valid */
        if (city_id && Number(city_id) > 0) {
            params.city_id = city_id;
        }
        if (s_city_id && Number(s_city_id) > 0) {
            params.s_city_id = s_city_id;
        }
    
        /*SAME SHIPPING BILLING
        =========================================================*/
        const sameShippingBilling = document.querySelector('input[pc="same-as"]') as HTMLInputElement;
        if (sameShippingBilling) {
            params.sameShippingBilling = sameShippingBilling.checked;
        }
    
        /*CHECK TERMS
        =========================================================*/
        const checkTerms = document.querySelector('input[pc="terms"]') as HTMLInputElement;
        if (checkTerms) {
            params.checkTerms = checkTerms.checked;
        }

        console.log('params', params);
    
        await updateInfo(params);
    
        return params;
    };
    
    /*=========================================================
    HANDLE INPUT CHANGE
    =========================================================*/
    const handleInputChange = _.debounce(async (event: Event) => {
        const params = await collectFormData();
        isFormComplete();
        
    }, 500);
    
    /*=========================================================
    HANDLE SELECT CHANGE
    =========================================================*/
    const handleSelectChange = async (event: Event) => {
        
        setTimeout(async () => {
            await collectFormData();

            /*IF CHECKBOX SAME SHIPPING BILLING IS CHECKED
            =========================================================*/
            const sameShippingBilling = document.querySelector('input[pc="same-as"]') as HTMLInputElement;
            if(sameShippingBilling) {
                if (sameShippingBilling.checked) {
                    await getOrderDetails({ page: 'checkout' });
                }
            }

            /*IF ONLY THE STATE CHANGE
            =========================================================*/
            if ((event.target as HTMLSelectElement).getAttribute('pc') === 's-state' || 
            (event.target as HTMLSelectElement).getAttribute('pc') === 'state' ||
            (event.target as HTMLSelectElement).getAttribute('pc') === 's-country' ||
            (event.target as HTMLSelectElement).getAttribute('pc') === 'country'
        ) {
                await getOrderDetails({ page: 'checkout' });
            }
            
            
            
        }, 500);
        
        isFormComplete();
        
    };
    
    /*=========================================================
    HANDLE AUTOFILL CHANGE
    =========================================================*/
    const handleAutofillChange = _.debounce(async (event: Event) => {
        const params = await collectFormData();
    }, 500);
    
    /*=========================================================
    HANDLE CHECKBOX CHANGE
    =========================================================*/
   /*=========================================================
HANDLE CHECKBOX CHANGE
=========================================================*/
const handleCheckboxChange = async (event: Event) => {
    const checkbox = event.target as HTMLInputElement;
    
    if (checkbox.checked) {
        // Ocultar shipping-information
        const shippingFieldsContainer = document.querySelector('[pc="shipping-information"]');
        if (shippingFieldsContainer) {
            shippingFieldsContainer.classList.add('hidden');
        }

        // Replicar los campos de billing a shipping
        const fieldsToReplicate = [
            { billing: 'fname', shipping: 's-fname' },
            { billing: 'lname', shipping: 's-lname' },
            { billing: 'address', shipping: 's-address' },
            { billing: 'address2', shipping: 's-address2' },
            { billing: 'postal', shipping: 's-postal' },
            { billing: 'city', shipping: 's-city' },
        ];

        fieldsToReplicate.forEach(({ billing, shipping }) => {
            const billingElement = document.querySelector(`input[pc="${billing}"]`) as HTMLInputElement;
            const shippingElement = document.querySelector(`input[pc="${shipping}"]`) as HTMLInputElement;

            if (billingElement && shippingElement) {
                shippingElement.value = billingElement.value;
                const event = new Event('input', { bubbles: true });
                shippingElement.dispatchEvent(event);
            }
        });

        // Replicar country y state
        const billingCountry = document.querySelector('select[pc="country"]') as HTMLSelectElement;
        const billingState = document.querySelector('select[pc="state"]') as HTMLSelectElement;
        const shippingCountry = document.querySelector('select[pc="s-country"]') as HTMLSelectElement;
        const shippingState = document.querySelector('select[pc="s-state"]') as HTMLSelectElement;

        if (billingCountry && shippingCountry && billingState && shippingState) {
            shippingCountry.value = billingCountry.value;
            await getRegions(Number(billingCountry.value), shippingCountry);

            shippingState.value = billingState.value;
            const stateChangeEvent = new Event('change', { bubbles: true });
            shippingState.dispatchEvent(stateChangeEvent);
        }
    } else {
        // Mostrar shipping-information
        const shippingFieldsContainer = document.querySelector('[pc="shipping-information"]');
        if (shippingFieldsContainer) {
            shippingFieldsContainer.classList.remove('hidden');
        }
    }

    isFormComplete();
};
    
    /*=========================================================
    ATTACH EVENT LISTENERS
    =========================================================*/
    const attachEventListeners = () => {
        const inputs = document.querySelectorAll('input[pc]');
        inputs.forEach(input => {
            const dataPc = input.getAttribute('pc');
            if (dataPc && dataPc !== 'city' && dataPc !== 's-city') {
                input.addEventListener('input', handleInputChange);
                input.addEventListener('change', handleAutofillChange);
            }
        });

        const selects = document.querySelectorAll('select[pc]');
        selects.forEach(select => {
            select.addEventListener('change', handleSelectChange);
        });

        const checkbox = document.querySelector('input[pc="same-as"]');
        if (checkbox) {
            checkbox.addEventListener('change', handleCheckboxChange);
        }
    };
    
    /*=========================================================
    UPDATE INFO
    =========================================================*/
    const updateInfo = async (params: any) => {
        await ApiRequest({
            url: '/orders/updateOrderInfo',
            method: 'post',
            body: {
                ...params,
                order_id: localStorage.getItem('order_id')
            },
            setResponse: async (response: any) => {
                // Handle response if needed
            }
        }); 
    }
    

    const SetClientInfo = async () => {
        console.log('orderDetails', orderDetails);
        
        // await Utils.getCountries();


        
        const elements = [
            { key: 'fname', selector: 'input[pc="fname"]' },
            { key: 'lname', selector: 'input[pc="lname"]' },
            { key: 'address', selector: 'input[pc="address"]' },
            { key: 'address2', selector: 'input[pc="address2"]' },
            { key: 'postal', selector: 'input[pc="postal"]' },
            { key: 'city', selector: 'input[pc="city"]' },
            { key: 'country_id', selector: 'select[pc="country"]' },

            { key: 's_fname', selector: 'input[pc="s-fname"]' },
            { key: 's_lname', selector: 'input[pc="s-lname"]' },
            { key: 's_address', selector: 'input[pc="s-address"]' },
            { key: 's_address2', selector: 'input[pc="s-address2"]' },
            { key: 's_postal', selector: 'input[pc="s-postal"]' },
            { key: 's_city', selector: 'input[pc="s-city"]' },
            { key: 's_country_id', selector: 'select[pc="s-country"]' },

            { key: 'email', selector: 'input[pc="email"]' },
            { key: 'phone', selector: 'input[pc="phone"]' },
        ];
    
        // Update country and wait for states to be populated
        const updateCountryAndState = async (countrySelector: string, stateSelector: string, countryId: number, stateId: number) => {
            console.log('updateCountryAndState', countrySelector, stateSelector, countryId, stateId);
            const countryElement = document.querySelector(countrySelector) as HTMLSelectElement;
            const stateElement = document.querySelector(stateSelector) as HTMLSelectElement;
    
            if (countryElement && stateElement) {
                countryElement.value = countryId.toString();
                await getRegions(countryId, countryElement);
                
    
                stateElement.value = stateId.toString();
                const stateChangeEvent = new Event('change');
                stateElement.dispatchEvent(stateChangeEvent);
            }
        };
    
        // Actualiza los valores de los inputs
        elements.forEach(({ key, selector }) => {
            const element = document.querySelector(selector) as HTMLInputElement | HTMLSelectElement;
            if (element && orderDetails[key] !== undefined && orderDetails[key] !== null && orderDetails[key] !== '' && orderDetails[key] !== 0) {
                element.value = orderDetails[key];
                const event = new Event('input', { bubbles: true });
                element.dispatchEvent(event);
            }
        });
        

        console.log('orderDetails', orderDetails);
        console.log('orderDetails.s_state_id', orderDetails.s_country_id);
        if (orderDetails.s_country_id !== undefined && orderDetails.s_country_id !== null) {
            await updateCountryAndState(
                'select[pc="s-country"]', 
                'select[pc="s-state"]', 
                orderDetails.s_country_id, 
                orderDetails.s_state_id || ''
            );
        }
        
        if (orderDetails.country_id !== undefined && orderDetails.country_id !== null) {
            await updateCountryAndState(
                'select[pc="country"]', 
                'select[pc="state"]', 
                orderDetails.country_id, 
                orderDetails.state_id || ''
            );
        }
    
        // Update same-shipping-billing checkbox
        const sameShippingBilling = document.querySelector('input[pc="same-as"]') as HTMLInputElement;
        
        if (sameShippingBilling && orderDetails.sameShippingBilling !== undefined) {
            sameShippingBilling.checked = orderDetails.sameShippingBilling;
            if (orderDetails.sameShippingBilling) {
                const event = new Event('change', { bubbles: true });
                sameShippingBilling.dispatchEvent(event);
    
                const shippingFieldsContainer = document.querySelector('[pc="shipping-information"]');
                if (shippingFieldsContainer) {
                    shippingFieldsContainer.classList.add('hidden');
                }
            }
            
        }
    
        /*=========================================================
        CHECK TERMS
        =========================================================*/
        const checkTerms = document.querySelector('input[pc="terms"]') as HTMLInputElement;
        if (checkTerms && orderDetails.checkTerms !== undefined) {

            if (orderDetails.checkTerms){
                checkTerms.checked = orderDetails.checkTerms;
                const event = new Event('change', { bubbles: true });
                checkTerms.dispatchEvent(event);
                isFormComplete();
            }
        }

        
        
        attachEventListeners();

        setIsFormInitialized(true); // Set form as initialized
    }

    return null
}

