import { ApiRequest } from "GlobalFunctions";
import { useEffect } from "react";

/*=========================================================
GET COUNTRIES
=========================================================*/
export default function Locations(props: any) {

    const {
        country_id,
        state_id,
        city_id,
        setCountryId,
        setStateId,
        setCityId,
        s_country_id,
        s_state_id,
        s_city_id,
        setSCountryId,
        setSStateId,
        setSCityId,
    } = props;

    useEffect(() => {
        getCountries();
    }, []);

    useEffect(() => {
        if (country_id) {
            clearStateAndCity();
            getRegions(country_id, 'state');
        }
    }, [country_id]);

    useEffect(() => {
        if (state_id) {
            clearCity();
            getCities(state_id, 'city');
        }
    }, [state_id]);

    useEffect(() => {
        if (s_country_id) {
            clearStateAndCity(true);
            getRegions(s_country_id, 's-state');
        }
    }, [s_country_id]);

    useEffect(() => {
        if (s_state_id) {
            clearCity(true);
            getCities(s_state_id, 's-city');
        }
    }, [s_state_id]);

    const clearStateAndCity = (isShipping = false) => {
        if (isShipping) {
            setSStateId('');
            setSCityId('');
            clearCityInput('s-city');
        } else {
            setStateId('');
            setCityId('');
            clearCityInput('city');
        }
    };

    const clearCity = (isShipping = false) => {
        if (isShipping) {
            setSCityId('');
            clearCityInput('s-city');
        } else {
            setCityId('');
            clearCityInput('city');
        }
    };

    const clearCityInput = (cityType: string) => {
        const element = document.querySelector(`[pc="${cityType}"]`) as HTMLInputElement;
        if (element) {
            element.value = '';
        }
    };

    const getCountries = async () => {
        console.log('getCountries??');
        await ApiRequest({
            url: '/countries/getCountries',
            method: 'GET',
            setResponse: (response: any) => {
                const countries = response;

                const billingCountry = document.querySelector('[pc="country"]');
                const country = document.querySelector('[pc="s-country"]');

                const fillCountrySelect = (selectElement: any) => {
                    if (selectElement) {
                        countries.forEach((country: any) => {
                            const option = document.createElement('option');
                            option.value = country.country_id;
                            option.innerHTML = country.country_name;
                            selectElement.appendChild(option);
                        });
                        // Add listener to select element
                        selectElement.addEventListener('change', (event: Event) => {
                            const target = event.target as HTMLSelectElement;
                            const countryId = target.value;
                            clearStateSelect(selectElement); // Clear states when country changes
                            getRegions(countryId, selectElement);
                            if (selectElement.getAttribute('pc') === "country") {
                                setCountryId(countryId);
                            } else if (selectElement.getAttribute('pc') === "s-country") {
                                setSCountryId(countryId);
                            }
                        });
                    }
                };

                const clearStateSelect = (selectElement: HTMLSelectElement) => {
                    const stateSelect = getStateSelect(selectElement);
                    if (stateSelect) {
                        stateSelect.innerHTML = '<option value="">Select State</option>'; // Clear and add default option
                    }
                };

                const getStateSelect = (selectElement: HTMLSelectElement) => {
                    const pcValue = selectElement.getAttribute('pc');
                    if (pcValue === "country") {
                        return document.querySelector('[pc="state"]');
                    } else if (pcValue === "s-country") {
                        return document.querySelector('[pc="s-state"]');
                    }
                    return null;
                };
                

                fillCountrySelect(billingCountry);
                fillCountrySelect(country);
            }
        });
    }



    /*=========================================================
    GET COUNTRY STATES
    =========================================================*/
    const getRegions = async (country_id: any, selectElement: HTMLSelectElement | string) => {
        await ApiRequest({
            url: '/countries/getRegions',
            method: 'GET',
            query: {
                country_id
            },
            setResponse: async (response: any) => {
                console.log(response, 'response', country_id, 'country_id', selectElement, 'selectElement');
                const states = response;

                const stateSelect = typeof selectElement === 'string' ? document.querySelector(`[pc="${selectElement}"]`) : getStateSelect(selectElement);
                if (stateSelect) {
                    // Clear previous states and add default empty option
                    stateSelect.innerHTML = '<option value="">Select State</option>';
                    states.forEach((state: any) => {
                        const option = document.createElement('option');
                        option.value = state.region_id;
                        option.innerHTML = state.region_name;
                        stateSelect.appendChild(option);
                    });
                    stateSelect.addEventListener('change', (event: Event) => {
                        const target = event.target as HTMLSelectElement;
                        const stateId = target.value;
                        if (stateSelect.getAttribute('pc') === "state") {
                            setStateId(stateId);
                        } else if (stateSelect.getAttribute('pc') === "s-state") {
                            setSStateId(stateId);
                        }
                    });
                }
            }
        });
    }

    const getCities = async (state_id: any, selectElement: string) => {
        await ApiRequest({
            url: '/countries/getCities',
            method: 'GET',
            query: {
                state_id
            },
            setResponse: (response: any) => {
                const cities = response;
                const citySelect = document.querySelector(`[pc="${selectElement}"]`);
                if (citySelect) {
                    citySelect.innerHTML = '<option value="">Select City</option>';
                    cities.forEach((city: any) => {
                        const option = document.createElement('option');
                        option.value = city.city_id;
                        option.innerHTML = city.city_name;
                        citySelect.appendChild(option);
                    });
                    citySelect.addEventListener('change', (event: Event) => {
                        const target = event.target as HTMLSelectElement;
                        const cityId = target.value;
                        if (citySelect.getAttribute('pc') === "city") {
                            setCityId(cityId);
                        } else if (citySelect.getAttribute('pc') === "s-city") {
                            setSCityId(cityId);
                        }
                    });
                }
            }
        });
    };

    /*=========================================================
    HELPER FUNCTIONS
    =========================================================*/
    const getStateSelect = (selectElement: HTMLSelectElement) => {
        console.log(selectElement.getAttribute('pc'), 'selectElement', selectElement);
        if (selectElement.getAttribute('pc') === "country") {
            return document.querySelector('[pc="state"]');
        } else if (selectElement.getAttribute('pc') === "s-country") {
            return document.querySelector('[pc="s-state"]');
        }
        return null;
    }

    return null
}


