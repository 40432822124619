import { ApiRequest, ShowLoader } from 'GlobalFunctions';
import { useEffect, useState } from 'react';
import GenerateVariants from './Variants';
import getDataAttributes from './Attributes';
import { showBag, getProductQuantity } from 'components/Bag/Bag';

export default function ProductUtils() {

    const [storeConfiguration, setStoreConfiguration] = useState<any>([]);
    /*=========================================================
    READ PRODUCT DATA
    =========================================================*/
    useEffect(() => {
        const selectors = [
            { attribute: "product-name" },
            { attribute: "product-price" },
            { attribute: "product-quantity" },
            { attribute: "product-sku" },
            { attribute: "product-image", target: "src" },
            { attribute: "tax-type" },
            { attribute: "product-weight" },
            { attribute: "product-category" },
            { attribute: "bulk-tiered-discounts" },
            { attribute: "v1-select" },
            { attribute: "v2-select" },
        ];

        /*=============================================
        GET ALL INPUTS VALUES INSIDE ADD TO CART FORM 
        =============================================*/
        const addToCartForm = document.querySelector("[pc='form']");

        const getCustomInputs = (form: HTMLFormElement) => {
            // Get inputs from form elements excluding those with  attribute
            const customInputs = Array.from(form.elements)
                .filter((element: any) => !element.hasAttribute('pc'))
                .map((element: any) => {
                    let value;
                    if (element.type === 'checkbox') {
                        if (!element.checked) return null;
                        value = element.checked;
                    } else if (element.type === 'radio') {
                        if (element.checked) {
                            value = element.value;
                        } else {
                            return null;
                        }
                    } else {
                        value = element.value;
                    }
                    return {
                        name: element.name,
                        value: value
                    };
                })
                .filter(input => input !== null);
        
            // Capture elements with pc-custom attribute
            const pcCustomElements = Array.from(document.querySelectorAll('[pc-custom]'))
                .map((element) => {
                    const htmlElement = element as HTMLElement; // Ensure the element is treated as HTMLElement
                    const name = htmlElement.getAttribute('pc-custom');
                    const value = htmlElement.innerText.trim(); // The text inside the tag will be the value
                    return {
                        name: name,
                        value: value
                    };
                });
        
            const variantAttributes = getVariantAttributes();
        
            // Combine all inputs (form inputs + pc-custom elements + variant attributes)
            return [...customInputs, ...pcCustomElements, ...variantAttributes];
        };
        




        



        /*=============================================
        GET VARIANT ATTRIBUTES
        =============================================*/
        const getVariantAttributes = () => {
            const variantAttributes: { name: string, value: string }[] = [];
            const variantSelectors = ["v1-select", "v2-select"];

            variantSelectors.forEach((selector, index) => {
                const inputs = document.querySelectorAll(`[pc='${selector}']`) as NodeListOf<HTMLInputElement>;
                inputs.forEach((input, i) => {
                    const value = input.value;
                    const name = input.getAttribute('name') || `custom_${index + 1}_${i + 1}`;
                    variantAttributes.push({ name, value });
                });
            });

            return variantAttributes;
        }

        const validateVariants = () => {
            const variantSelectors = ["v1-select", "v2-select"];
            for (const selector of variantSelectors) {
                const inputs = document.querySelectorAll(`[pc='${selector}']`) as NodeListOf<HTMLInputElement>;
            }
            return true;
        }

        const addToCart = document.querySelector("[pc='add-btn']");
        if (addToCart) {
            addToCart.addEventListener("click", async () => {
                if (!validateVariants()) return;
                
                const productData = await getDataAttributes(selectors);
                if (addToCartForm) {
                    productData.custom_inputs = getCustomInputs(addToCartForm as HTMLFormElement);
                }
                productData.order_id = localStorage.getItem('order_id');
                AddToCart(productData);
            });
        }

        
    }, []);

    GenerateVariants();

    useEffect(() => {
        handleQuantityInput()
    }, [storeConfiguration]);


    const handleQuantityInput = async () => {
        const quantityInput = document.querySelector("[pc='product-quantity']") as HTMLInputElement;
        const priceElement = document.querySelector("[pc='product-price']") as HTMLInputElement;
        const discountElement = document.querySelector("[pc='bulk-tiered-discounts']") as HTMLDivElement;
    
        console.log("QUANTITY", quantityInput.value);
    
        // Verificar si el descuento "bulk_tiered_discounts" está activo en storeConfiguration usando `any` en `config`
        const bulkTieredDiscountConfig = storeConfiguration.find(
            (config: any) => config.logic === "bulk_tiered_discounts" && config.is_active
        );
    
        if (quantityInput) {
            let basePrice = priceElement ? parseFloat(priceElement.innerText) || 0 : 0;
    
            const discounts =
                bulkTieredDiscountConfig && discountElement
                    ? discountElement.innerText
                          .split(',')
                          .map((discount) => {
                              const [price, discount_quantity] = discount
                                  .split(':')
                                  .map((item) => parseFloat(item));
                              return { price, discount_quantity };
                          })
                          .sort((a, b) => a.discount_quantity - b.discount_quantity)
                    : []; // Ordenar por cantidad ascendente o mantener vacío
    
            const updatePriceBasedOnQuantity = (quantity: number) => {
                if (bulkTieredDiscountConfig && priceElement && discounts.length > 0) {
                    let newPrice = basePrice;
    
                    for (const discount of discounts) {
                        if (quantity >= discount.discount_quantity) {
                            newPrice = discount.price;
                        } else {
                            break;
                        }
                    }
    
                    priceElement.innerText = newPrice.toFixed(2);
                }
            };
    
            const validateAndSetQuantity = () => {
                let value = parseInt(quantityInput.value);
                if (isNaN(value) || value < 1) {
                    quantityInput.value = '1';
                    value = 1;
                }
                updatePriceBasedOnQuantity(value);
            };
    
            quantityInput.addEventListener("input", () => {
                // Permitir edición libre sin validar inmediatamente
                const value = parseInt(quantityInput.value);
                if (!isNaN(value) && value >= 1) {
                    updatePriceBasedOnQuantity(value);
                }
            });
    
            quantityInput.addEventListener("blur", () => {
                // Validar y ajustar el valor cuando se pierde el foco
                validateAndSetQuantity();
            });
    
            // Validar el input al cargar la página
            validateAndSetQuantity();
        }
    };
    
    


    /*=========================================================
    CHECK BULK TIERED DISCOUNTS IS ACTIVE
    =========================================================*/
    useEffect(() => {
        getStoreConfiguration();
    }, []);
    const getStoreConfiguration = async () => {
        await ApiRequest({
            url: "/logic/storeConfiguration",
            method: "get",
            query: {
                order_id: localStorage.getItem('order_id'),
            },
            setResponse: (response: any) => {
                setStoreConfiguration(response);
            },
        });
    }

    return null;
}









/*=========================================================
ADD TO CART
=========================================================*/
const AddToCart = async (productData: any) => {
    
    ShowLoader(true);
    await ApiRequest({
        url: "/orders/addToCart",
        method: "post",
        body: {
            ...productData,
        },
        setResponse: async (response: any) => {
            localStorage.setItem('order_id', response.data.order_id);
            await getProductQuantity();
            showBag();
        },
    });
}

